import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import LoaderFullScreen from '~/components/LoaderFullScreen'
import LottieAnimator from '~/components/LottieAnimator'
import OTPLogin from '~/components/OtpLogin'
import Tour from '~/components/Tour'
import { router } from '~/lib/router'
import { capitalize, isEmpty } from 'lodash'

import Header from '~/components/Header'
import Sidebar from '~/components/Sidebar'

import * as style from './styles.js'

const SCROLL_LOCK_ROUTES = [
  // '/',
]

const SyncLoader = ({ syncStatus = '' }) => {
  if (!syncStatus) {
    return null
  }

  let text = ''

  if (syncStatus === 'pending') {
    text = 'Preparing to sync your changes...'
  } else if (syncStatus === 'success') {
    text = 'Changes have been saved!'
  } else if (syncStatus === 'failure') {
    text = 'Something went wrong. Try again later.'
  }

  return (
    <div css={style.loaderSync}>
      <LottieAnimator animation={`sync${capitalize(syncStatus)}`} />
      <span>{text}</span>
    </div>
  )
}

/**
 * StandardLayout
 *
 * @component
 * @usage `import StandardLayout from '~/layouts/Standard'`
 * @example
 * // Export the page using the HOC:
 * export default StandardLayout(MyPage)
 */
const StandardLayout = ({ rootStore, children, currentRoute }) => {
  const isLayoutLocked = SCROLL_LOCK_ROUTES.includes(currentRoute.path)
  const { stateStore, authStore, resumesStore, attachmentsStore } = rootStore
  const { isLoading, syncStatus, isLoginOpen } = stateStore
  const { isLoggedIn } = authStore

  const handleRedirect = async () => {
    await resumesStore.refreshResume()

    if (authStore.isLoggedIn && !isEmpty(resumesStore.resume)) {
      router.push(router.routes.scores)
    }
  }

  const handleEditLatestPDF = () => {
    attachmentsStore.getLatestAttachment()
    router.push(router.routes.review)
  }

  const handleOpenLoginWindow = () => {
    stateStore.set({ isLoginOpen: true, onLoginSuccess: () => handleRedirect() })
  }

  const handleLogout = () => {
    router.push(router.routes.home)
    authStore.logout()
  }

  const handleClickTour = () => {
    rootStore.stateStore.set({ hasSeenTour: false })
    router.push(router.routes.edit)
  }

  return (
    <main css={style.layout(isLayoutLocked)}>
      <Header
        isLoggedIn={isLoggedIn}
        currentRoute={currentRoute}
        onClickEditor={handleEditLatestPDF}
        onClickLogin={handleOpenLoginWindow}
        onClickLogout={handleLogout}
        onClickTour={handleClickTour}
        rootStore={rootStore}
      />
      {children}
      {isLoading && <LoaderFullScreen />}
      <SyncLoader syncStatus={syncStatus} />
      <OTPLogin
        isActive={isLoginOpen}
        onClose={() => stateStore.set({ isLoginOpen: false })}
      />
      <Tour />
    </main>
  )
}

StandardLayout.propTypes = {
}

StandardLayout.defaultProps = {
}


export default observer(StandardLayout)
