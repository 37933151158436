import NextRouter from 'next/router'
import NextLink from 'next/link'
import appRoutes from '~/routes'
import { Router } from '~/resources/libs'

const routesPresented = Router.provideRoutes(appRoutes)
const routesByPath = Router.getRoutesByPath(routesPresented)
const Link = (props) => Router.Link(props, NextLink)
const router = {
  replace: (href, params, ctx) => Router.routerReplace(href, params, ctx, NextRouter),
  push: (href, params, ctx) => Router.routerPush(href, params, ctx, NextRouter),
  routes: routesPresented,
  routesByPath,
  getCurrentRoute: (ctx) => Router.getCurrentRoute(routesByPath, ctx, NextRouter),
}

export {
  router,
  Link,
}
