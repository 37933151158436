import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from '~/styles/colors'
import { zIndexes } from '~/styles/constants'

const ERROR_COLOR = '#fde9ef'

export const container = css`
  position: relative;
  margin-top: 0.5rem; // Make space for label
  margin-bottom: 1.5rem;
`

export const label = hasError => css`
  position: absolute;
  background: ${colors.white};
  top: -0.65rem;
  left: 1rem;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  color: ${colors.greyDark};
  margin-bottom: 0.5rem;
  z-index: ${zIndexes.middle};
  transition: color ease-in-out 0.3s;

  ${hasError && `
    background: linear-gradient(to bottom, ${colors.white} 65%, ${colors.transparent} 40%);
    color: ${colors.red};
  `};
`

export const input = hasError => css`
  &, .rs-picker-toggle {
    ${hasError && `background-color: ${ERROR_COLOR} !important;`};
  }

  &:focus, &:hover {
    & + label {
      color: ${colors.blue};
    }
  }
`

export const requiredAsterisk = css`
  color: ${colors.red};
  margin: 0 0.25rem;
`
