import { IsServer, IsTest } from '~/resources/utils'

let clientSideStore

const getRootStore = (initialData = {}, stores) => {
  if (IsTest) {
    clientSideStore = undefined
  }
  const rootStore = {}
  if (IsServer || !clientSideStore) {
    Object.keys(stores).forEach(key => {
      rootStore[key] = new stores[key]({ ...initialData[key], rootStore })

      Object.keys(rootStore).forEach(key => {
        rootStore[key].setRootStore(rootStore)
      })
    })
  }
  if(!IsServer && !clientSideStore) {
    clientSideStore = rootStore
  }
  return IsServer ? rootStore : clientSideStore
}

const RootStore = {
  getRootStore,
}
export default RootStore
