export default [
  {
    selector: 'sidebar_scores',
    content: 'We have detected and scored 3 positions that match your profile. Feel free to change them.',
    isResponsive: true,
  },
  {
    selector: 'carousel_scores',
    content: 'This is the current section\'s score. Note that the colors match your titles\' colors.',
  },
  {
    selector: 'recommendation_cards',
    content: 'This is a suggestion that would increase your scores for the section you\'re currently in.',
    isResponsive: true,
    childSelector: 'recommendation_card',
    order: 1,
  },
  {
    selector: 'recommendation_cards',
    content: 'These numbers are the expected increments for the scores in the section you\'re currently in.',
    isResponsive: true,
    childSelector: 'recommendation_scores',
    order: 1,
  },
  {
    selector: 'recommendation_cards',
    content: 'Click on the suggestion card to apply.',
    isResponsive: true,
    childSelector: 'recommendation_card',
    order: 1,
    canInteract: true,
    shouldDisableNext: true,
    action: () => {
      const element = document.getElementsByClassName('rs-modal-header-close')

      if (element[0]) { // If the modal is open, close it
        element[0].click()
      }
    },
  },
  {
    rawSelector: '[id="tour-recommendation_modal"]',
    content: 'You can select as many sentences as you\'d like and can then click \'Paste\'. If you want to go back, click \'Cancel\'.',
    canInteract: true,
    shouldDisableNext: true,
    position: 'bottom',
  },
  {
    selector: 'carousel_scores',
    content: 'After pasting suggested sentences, or editing any of the existing text in this section, the sections\'s scores will change to reflect the changes.',
  },
  {
    selector: 'sidebar_scores',
    content: 'Your overall scores will also be affected by these changes.',
    isResponsive: true,
  },
  // ! HIT PASTE => REFRESH SCORE => HIGHLIGHT SECTION SCORES and GENERAL SCORES
  // {
  //   selector: 'recommendation_cards',
  //   content: 'STEP 5',
  //   isResponsive: true,
  //   childSelector: 'recommendation_price',
  //   order: 1,
  // },
]
