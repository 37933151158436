
import { IsDevelopment, IsServer } from '~/resources/utils'
import { router } from '~/lib/router'
import { GA_CODE } from '~/global.config'
import { CookieHelper } from '~/resources/helpers'

const CONDITIONS = !IsDevelopment && !IsServer && window.gtag

const tracker = {
  pageView: (url) => {
    if (CONDITIONS) {
      window.gtag('config', GA_CODE, {
        page_path: url,
      })
    }
  },

  event: (name = '', extra = {}) => {
    if (CONDITIONS) {
      const { currentPath } = router.getCurrentRoute()
      // ! Todo: Privacy concern: Remove this as we shouldn't be tracking IDs
      const cookie = CookieHelper.getCookieData(document.cookie)
      const currentUser = cookie._currentUser
      const userId = currentUser ? currentUser.id : null

      if (userId) {
        extra.id = userId
      }

      window.gtag('event', name, { currentPath, ...extra })
    }
  },
}


export default tracker

// ? About gtag:
// ! Todo: This is an experimental solution pushed out by NextJS creators, Vercel.
// ! In the future, a plugin should be out. Progress can be tracked here:
// ! https://github.com/vercel/next.js/tree/canary/packages/next-plugin-google-analytics
// ! To remove this experimental fix, remove it's presence from `_document.js` and
// ! wherever this lib is being imported and used.
// gtag config: https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// gtag event: https://developers.google.com/analytics/devguides/collection/gtagjs/events
