import { css } from '@emotion/react'
import colors from '~/styles/colors'

export const selectValueContainer = css`
  display: flex;
  align-items: center;
  width: 100%;
`

export const colorIndicator = color => {
  if (!color) {
    return css``
  }

  return css`
    display: inline-table;
    height: 0.75rem;
    width: 0.75rem;
    background: ${colors[color]};
    margin-right: 0.25rem;
    border-radius: 100%;
  `
}

export const loaderContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`

export const loaderIcon = css`
  margin-right: 0.5rem;
`

export const block = css`
  display: block;
  width: 100%;
  border: 1px solid blue;
`

