import { transparentize } from 'polished'

const COLORS = {
  black: '#000',
  white: '#FFF',
  blue: '#1174D5',
  blueDisabled: '#E2ECF8',
  blueLight: '#99C4FF',
  blueLightDisabled: '#EAF3FF',
  blueDark: '#0059CD',
  cyan: '#AAFFFD',
  cyanDisabled: '#EEFFFE',
  purple: '#B692FF',
  purpleDisabled: '#F0E8FF',
  green: '#30cb00',
  greenDark: '#006203',
  red: '#d62828',
  redDark: '#800808',
  orange: '#F97B31',
  greyLight: '#E5E5E5',
  greyDark: '#C1C1C1',
  transparent: '#FFF0',
}

const THEME_COLORS = {
  primary: COLORS.blueLight,
  secondary: COLORS.cyan,
  tertiary: COLORS.purple,
  primaryAlt: COLORS.blueLightDisabled,
  secondaryAlt: COLORS.cyanDisabled,
  tertiaryAlt: COLORS.purpleDisabled,
  shadow: transparentize(0.85, COLORS.black),
}

const CUSTOM_COLORS = {
  landingPageLightBlue: '#1174D5',
  landingPageDarkBlue: '#0F2943',
}

export default {
  ...COLORS,
  ...THEME_COLORS,
  ...CUSTOM_COLORS,
}
