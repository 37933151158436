import Store from './Store'
import TitlesService from '~/network/TitlesService.js'
import { observable, action, computed, toJS } from 'mobx'
import { uniqBy, isEmpty } from 'lodash'

import { debounce } from 'lodash'

const DEBOUNCE_TIME = 500 // in ms

class TitlesStore extends Store {
  @observable _titles = [];
  @observable _isLoading = false;
  @observable _defaultTitles = [];

  constructor(initialData = {}) {
    super(initialData)

    this.search = debounce(this.search, DEBOUNCE_TIME)
  }

  service() {
    return new TitlesService(this.rootStore)
  }

  @action onLogout() {
    this.clearStore()
  }

  @action clearStore() {
    this._titles = []
  }

  async search(keyword) {
    this._isLoading = true

    const resp = await this.storeApiResponse({
      apiName: 'get',
      onSuccess: resp => resp && resp.data && resp.data.items && this.addTitles(resp.data.items),
      params: { keyword },
    })

    if (_.isEmpty(keyword)) {
      this._defaultTitles = resp.data.items
    }

    this._isLoading = false
  }

  @computed get defaultTitles() {
    return toJS(this._defaultTitles)
  }

  @action setTitles(newTitles) {
    this._titles = newTitles
  }

  @computed get titles() {
    return toJS(this._titles)
  }

  @action addTitles(titles) {
    this._titles = uniqBy([
      ...toJS(titles) || [],
      ...this._titles || [],
    ], 'id'
    )
  }

  @computed get titlesUnique() {
    return uniqBy([
      ...this.defaultTitles || [],
      ...this.titles || [],
      ...this.rootStore.resumesStore.resume.titles || [],
    ], 'id'
    )
  }

  @computed get isLoading() {
    return toJS(this._isLoading)
  }
}

export default TitlesStore
