import Store from './Store'
import ResumesService from '~/network/ResumesService.js'
import { observable, action, computed, toJS } from 'mobx'

import { router } from '~/lib/router'
import { isEmpty, debounce } from 'lodash'
import parseExistingResume from '~/presenters/existingResumeParser'

const WAIT_FOR_RESUME_DURATION = 3000 // in ms
const DEBOUNCE_TIME = 200 // in ms

class ResumesStore extends Store {
  @observable _resume = {}
  @observable _uploadedData = {}
  @observable _recommendations = {}
  @observable _courses = {}
  @observable _lastRecommendationsParams = {} // todo: Delete if not used?
  @observable _areRecommendationsLoading = false
  @observable _error = ''
  @observable _reviewedResume = {}

  constructor(initialData = {}) {
    super(initialData)

    this.updateDebounced = debounce(this.updateDebounced, DEBOUNCE_TIME)
  }

  service() {
    return new ResumesService(this.rootStore)
  }

  async refreshResume() {
    const resume = await this.storeApiResponse({
      apiName: 'getCurrent',
      onSuccess: resp => this.setResume(resp.data),
    })

    return resume?.data
  }

  async confirmResumeIsCreated(redirectTo, redirectParams = {}) {
    const { stateStore, titlesStore } = this.rootStore
    const currentResume = await this.refreshResume()

    if (currentResume && currentResume.status === 'processed') {
      const currentRoute = router.getCurrentRoute()
      stateStore.set({ isLoading: false })

      if (redirectTo && currentRoute.key !== redirectTo.key) {
        router.push(redirectTo, redirectParams)
      }

      titlesStore.search()

      return this.resume
    }

    setTimeout(() => { this.confirmResumeIsCreated(redirectTo, redirectParams) }, WAIT_FOR_RESUME_DURATION)

    return null
  }

  async uploadResume(props) {
    const uploadPost = await this.api().upload(props)
    this._uploadedData = uploadPost.data
    return uploadPost
  }

  async createAndWait({ redirectTo, redirectParams, ...params }) {
    let hasSucceeded = false
    this.rootStore.stateStore.set({ isLoading: true })

    await this.storeApiResponse({ // todo: Rename this function if it doesn't serve its initial purpose?
      apiName: 'create',
      params,
      onSuccess: () => hasSucceeded = true,
      onFailure: error => this.error = error,
    })

    if (hasSucceeded) {
      return await this.confirmResumeIsCreated(redirectTo, redirectParams)
    }

    return this.error
  }

  async getLinkedInResume() {
    const { authStore } = this.rootStore

    const token = `Bearer ${authStore.linkedInToken}`
    let resume = {}
    const usr = await fetch('https://api.linkedin.com/v2/me', {
      method: 'GET',
      headers: { Connection: 'Keep-Alive', Authorization: token },
    })

    if (usr.ok) {
      resume = await usr.json()
      console.log('[ i ]: getLinkedInResume -> resume', resume)
      // this.createAndWait({ data: resume })
    }
  }

  async updateDebounced(params) {
    const { stateStore } = this.rootStore

    return await this.storeApiResponse({
      apiName: 'create',
      params,
      onSuccess: () => {
        this.refreshResume()
        stateStore.setSyncStatus('success')
      },
      onFailure: () => {
        stateStore.setSyncStatus('failure')
      },
    })
  }

  async sync(params) {
    const { stateStore } = this.rootStore

    const paramsStripped = { ...params }
    paramsStripped.data.experience.forEach(exp => {
      delete exp.scores
    })

    stateStore.setSyncStatus('pending')
    return await this.updateDebounced({
      titles: this.resume.titles.map(title => title.id),
      ...paramsStripped,
    })
  }

  async getRecommendations(params, { shouldRefresh, withLoading } = {}) {
    if (this.areRecommendationsLoading) {
      return null
    }

    this._lastRecommendationsParams = params
    withLoading !== false && this.setAreRecommendatonsLoading(true)
    const resume = await this.storeApiResponse({
      apiName: 'getRecommendations',
      onSuccess: resp => {
        const funcParams = { key: params.section, value: resp.data }
        shouldRefresh
          ? this.replaceRecommendations(funcParams)
          : this.appendRecommendationToKey(funcParams)
      },
      params: {
        ...params,
        id: this.resume.id,
      },
    })

    withLoading !== false && this.setAreRecommendatonsLoading(false)
    return resume
  }

  async getCourses(params) {
    if (this.areRecommendationsLoading) {
      return null
    }

    this.setAreRecommendatonsLoading(true)
    const resume = await this.storeApiResponse({
      apiName: 'getCourses',
      onSuccess: resp => {
        const funcParams = { key: params.section, value: resp.data }
        this.appendCoursesToKey(funcParams)
      },
      params: {
        ...params,
        id: this.resume.id,
      },
    })

    this.setAreRecommendatonsLoading(false)
    return resume
  }

  clearRecommendations() {
    this._recommendations = {}
    this._lastRecommendationsParams = {}
    this._areRecommendationsLoading = false
  }

  rateRecommendation({ id, rating, slug }) {
    const foundIndex = this.recommendations[slug].findIndex(rec => rec.id === id)
    this._recommendations[slug][foundIndex].user_action = rating
  }

  @action onLogout() {
    this.clearStore()
  }

  @action clearStore() {
    this._uploadedData = {}
    this._recommendations = {}
    this._courses = {}
    this._reviewedResume = {}
    this._lastRecommendationsParams = {}
    this._areRecommendationsLoading = false
    this._error = ''
  }

  @action setReviewedResume(value) {
    this._reviewedResume = value
  }

  @action setResume(value) {
    this._resume = parseExistingResume(value)
  }

  @action setAreRecommendatonsLoading(value) {
    this._areRecommendationsLoading = value
  }

  @action replaceRecommendations({ key, value }) {
    this._recommendations[key] = value
  }

  @action appendRecommendationToKey({ key, value }) {
    if (this.recommendations[key]) {
      this._recommendations[key] = [
        ...this.recommendations[key],
        ...value,
      ]
    } else {
      this._recommendations[key] = value
    }
  }

  @action appendCoursesToKey({ key, value }) {
    if (this.courses[key]) {
      this._courses[key] = [
        ...this.courses[key],
        ...value,
      ]
    } else {
      this._courses[key] = value
    }
  }


  @action setUploadedDataPdfAttachment(pdf_attachment) {
    this._uploadedData = {
      pdf_attachment,
      resume: this.resume.details || {},
    }
  }

  @computed get resume() {
    return toJS(this._resume)
  }

  @computed get uploadedData() {
    return toJS(this._uploadedData)
  }

  @computed get recommendations() {
    return toJS(this._recommendations)
  }

  @computed get courses() {
    return toJS(this._courses)
  }

  @computed get reviewedResume() {
    return toJS(this._reviewedResume)
  }

  @computed get lastRecommendationsParams() {
    return toJS(this._lastRecommendationsParams)
  }

  @computed get areRecommendationsLoading() {
    return toJS(this._areRecommendationsLoading)
  }

  @computed get doesResumeExist() {
    return toJS(!isEmpty(this.resume))
  }

  @computed get error() { // todo: This isn't being used
    return toJS(this._error)
  }
}

export default ResumesStore
