const NextI18Next = require('next-i18next').default
const { applyClientHMR } = require('i18next-hmr/client')

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: [''],
  localePath: './public/static/locales',
})

if (process.env.NODE_ENV === 'development') {
  applyClientHMR(NextI18Next.i18n)
}

module.exports = NextI18NextInstance
