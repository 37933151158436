export const SEO = {
  title: 'AI Recruiter',
  description: 'AI Recruiter uses AI technology to rate and improve your resume',
  robots: 'noodp',
  keywords: 'next, js, nextjs, blueprint',
  url: 'https://airesumes.com/',
  tileColor: '#343a40',
  tileImage: 'static/logo-red.png',
  color: '#FF0000',
}

export const OG = {
  locale: 'en_US',
  type: 'website',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  siteName: SEO.title,
  image: SEO.image,
}

export const TWITTER = {
  card: 'summary',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  image: SEO.image,
}
