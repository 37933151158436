import React from 'react'
import PropTypes from 'prop-types'

import Lottie from 'react-lottie'

import syncPending from '~/public/static/lottie/sync-pending.json'
import syncSuccess from '~/public/static/lottie/sync-success.json'
import syncFailure from '~/public/static/lottie/sync-failure.json'

import * as style from './styles'

const DEFAULT_OPTIONS = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const ANIMATION_DATA = {
  syncPending: {
    animationData: syncPending,
    loop: true,
  },
  syncSuccess: {
    animationData: syncSuccess,
    loop: false,
  },
  syncFailure: {
    animationData: syncFailure,
    loop: false,
  },
}

/**
 * <Describe the LottieAnimator component here>
 *
 * @component
 * @usage import LottieAnimator from '~/components/LottieAnimator'
 * @example
 * <Add an example of how LottieAnimator is used here>
 */
const LottieAnimator = ({ animation, ...extra }) => {
  return (
    <div {...extra}>
      <Lottie
        options={{
          ...DEFAULT_OPTIONS,
          ...ANIMATION_DATA[animation],
        }}
      />
    </div>
  )
}

LottieAnimator.propTypes = {
  animation: PropTypes.string,
}

LottieAnimator.defaultProps = {
  animation: '',
}

export default LottieAnimator
