import { serialize } from 'object-to-formdata'

/**
 * Function to format and return formData from object of params.
 * @function
 * @usage `import { generateFormData } from '~/helpers/formDataHelper'`
 * @param {object} args - Object of parameters
 * @returns {FormData}
 */
const generateFormData = ({ attachments, file, ...data } = {}) => {
  const formData = serialize(data)

  if (file) {
    if (file.file) {
      formData.append('file', file.file)
    } else {
      formData.append('file', file)
    }
  }

  if (attachments) {
    attachments.forEach(attachment => {
      if (attachment.file) {
        formData.append('attachments_to_upload[]', attachment.file)
      } else {
        formData.append('attachments_to_upload[]', attachment)
      }
    })
  }

  return formData
}

export default { generateFormData }
