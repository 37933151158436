import { RootStore } from '~/resources/stores'

import AuthStore from './AuthStore'
import DevicesStore from './DevicesStore'
import ResumesStore from './ResumesStore'
import TitlesStore from './TitlesStore'
import StateStore from './StateStore'
import RecommendationsStore from './RecommendationsStore'
import AttachmentsStore from './AttachmentsStore'
// * [reserved] [import_generated_store]

const STORES = {
  authStore: AuthStore,
  devicesStore: DevicesStore,
  resumesStore: ResumesStore,
  titlesStore: TitlesStore,
  stateStore: StateStore,
  recommendationsStore: RecommendationsStore,
  attachmentsStore: AttachmentsStore,
  // * [reserved] [declare_generated_store]
}

const getRootStore = (initialData) => RootStore.getRootStore(initialData, STORES)

export {
  getRootStore,
}