/**
 * Does something.
 * @function
 * @usage `import { doSomething } from '~/helpers/timeHelper'`
 * @param {object} args - The object containing the arguments
 */
export const getDifferenceInSeconds = (firstDate, secondDate) => {
  return (new Date(firstDate).getTime() - new Date(secondDate).getTime()) / 1000
}

export const numberToTime = timeNumber => {
  let hours = Math.floor(timeNumber / 3600)
  let minutes = Math.floor((timeNumber - hours * 3600) / 60)
  let seconds = timeNumber - hours * 3600 - minutes * 60

  if (hours < 10) { hours = `0${hours}` }
  if (minutes < 10) { minutes = `0${minutes}` }
  if (seconds < 10) { seconds = `0${seconds}` }

  let finalString = `${minutes}:${seconds}`

  if (hours !== '00') {
    finalString = `${hours}:${finalString}`
  }

  return finalString
}

export const timeToNumber = time => {
  if (time !== null && time !== undefined) {
    const arr = time.toString().split(':')
    const dec = parseInt(arr[1] / 6 * 10, 10)

    return parseFloat(`${parseInt(arr[0], 10)}.${dec < 10 ? '0' : ''}${dec}`)
  }

  return ''
}

export const isValidDate = (date) => {
  return !isNaN(date.getTime())
}

export const formatDate = (dateRaw, formatRaw) => {
  const delimiter = '-'
  const formats = formatRaw.split(delimiter)
  const date = new Date(dateRaw)

  const dateFragments = formats.map(format => {
    const formatLowered = format.toLowerCase()
    let number = ''

    if (formatLowered === 'yyyy') {
      number = date.getFullYear()
    } else if (formatLowered === 'mm') {
      number = date.getMonth() + 1
    } else if (formatLowered === 'dd') {
      number = date.getDate()
    }

    return number < 10 ? `0${number}` : number
  })

  return dateFragments.join(delimiter)
}
