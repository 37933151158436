import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/**
 * This component contains all SEO metadata tags which are used in the page
 */
const Meta = ({
  isHead,
  HeadComponent,
  allTags,
  general,
  twitter,
  openGraph,
}) => {
  const GENERAL_META = { ...allTags, ...general }
  const TWITTER_META = { ...allTags, ...twitter }
  const OPEN_GRAPH_META = { ...allTags, ...openGraph }

  const ParentTag = isHead ? HeadComponent : Fragment

  const hasGeneralMetaTags = Object.keys(GENERAL_META).length > 0
  const hasTwitterMetaTags = Object.keys(TWITTER_META).length > 0
  const hasOpenGraphMetaTags = Object.keys(OPEN_GRAPH_META).length > 0

  return (
    <ParentTag>
      {/* General Meta Tags */}
      {hasGeneralMetaTags && (
        <Fragment>
          <title>{GENERAL_META.title}</title>
          <meta name='msapplication-TileColor'
            content={GENERAL_META.tileColor}/>
          <meta name='msapplication-TileImage'
            content={GENERAL_META.tileImage}/>
          <meta name='theme-color' content={GENERAL_META.color}/>
          <meta name='description' content={GENERAL_META.description}/>
          <meta name='robots' content={GENERAL_META.robots}/>
          <meta name='keywords' content={GENERAL_META.keywords}/>
          <meta name='url' content={GENERAL_META.url}/>
          <meta name='image' content={GENERAL_META.image}/>
        </Fragment>
      )}
      {/* Twitter Meta Tags*/}
      {hasTwitterMetaTags && (
        <Fragment>
          <meta key='meta_twitter-site' name='twitter:site'
            content={TWITTER_META.url}/>
          <meta key='meta_twitter-image' name='twitter:image'
            content={TWITTER_META.image}/>
          <meta key='meta_twitter-card' name='twitter:card'
            content={TWITTER_META.card}/>
          <meta key='meta_twitter-title' name='twitter:title'
            content={TWITTER_META.title}/>
          <meta key='meta_twitter-description' name='twitter:description'
            content={TWITTER_META.description}/>
        </Fragment>
      )}
      {/* Open Graph Meta Tags*/}
      {hasOpenGraphMetaTags && (
        <Fragment>
          <meta key='meta_og-url' property='og:url'
            content={OPEN_GRAPH_META.url}/>
          <meta key='meta_og-site_name' property='og:site_name'
            content={OPEN_GRAPH_META.siteName}/>
          <meta key='meta_og-image' property='og:image'
            content={OPEN_GRAPH_META.image}/>
          <meta key='meta_og-locale' property='og:locale'
            content={OPEN_GRAPH_META.locale}/>
          <meta key='meta_og-type' property='og:type'
            content={OPEN_GRAPH_META.type}/>
          <meta key='meta_og-title' property='og:title'
            content={OPEN_GRAPH_META.title}/>
          <meta key='meta_og-description' property='og:description'
            content={OPEN_GRAPH_META.description}/>
        </Fragment>
      )}
    </ParentTag>
  )
}

Meta.propTypes = {
  /**
   * if `isHead` is true, then tags are wrapped in the <head/> tag
   */
  isHead: PropTypes.bool, //
  /**
   * To keep the header component elements, this is rendered only if `isHead` is true
   */
  HeadComponent: PropTypes.any,
  /**
   * SEO metadata tags which are applied for all SEO tags
   */
  allTags: PropTypes.object,
  /**
   * SEO meta tags which are available in the SE but do not affect other vendor specific SEO metadata tags
   */
  general: PropTypes.object,
  /**
   * Twitter specific SEO metadata tags
   */
  twitter: PropTypes.object,
  /**
   * Open graph specific SEO metadata tags
   */
  openGraph: PropTypes.object,
}

Meta.defaultProps = {
  isHead: true,
  allTags: {},
  general: {},
  twitter: {},
  openGraph: {},
}

export default Meta
