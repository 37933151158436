import React from 'react'
import App from 'next/app'
import NextHead from 'next/head'

import * as Sentry from '@sentry/browser'
import { appWithTranslation } from '~/i18n'
import { getRootStore } from '~/stores/RootStore'
import { Provider } from 'mobx-react'
import { CookieHelper } from '~/resources/helpers'
import { withRouter } from 'next/router'
import stringify from 'json-stringify-safe'
import { IsDevelopment, IsServer } from '~/resources/utils'
import { router } from '~/lib/router'
import { SEO } from '~/components/Meta'
import tracker from '~/lib/tracker'
import StandardLayout from '~/layouts/Standard'
import { CONFIG, SENTRY_DSN } from '~/global.config'

import 'rsuite/dist/styles/rsuite-default.css'
import './_app.scss'

if (!IsDevelopment && CONFIG.withSentry) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: IsDevelopment ? 'development' : 'production',
  })

  process.on('unhandledRejection', err => Sentry.captureException(err))
  process.on('uncaughtException', err => Sentry.captureException(err))
}

class NextJSMobxApp extends App {
  static async getInitialProps(appContext) {
    const cookieObject = appContext.ctx.req
        ? CookieHelper.getCookieData(appContext.ctx.req.headers.cookie) // Server side
        : CookieHelper.getCookieData(document.cookie)

    // ? On server-side, this runs once and creates new stores
    const rootStore = getRootStore({ authStore: cookieObject })

    // ? Make stores available to page's `getInitialProps`
    appContext.ctx.rootStore = rootStore

    // ? Call "super" to run page's `getInitialProps`
    const appProps = await App.getInitialProps(appContext)

    // ? Generate a serialized RootStore to pass below (in return)
    // ? __NEXT_DATA
    const rootStoreString = stringify(rootStore, null)

    return {
      ...appProps,
      currentRoute: router.getCurrentRoute(appContext.ctx),
      rootStoreString,
    }
  }

  constructor(props) {
    super(props)
    const { rootStoreString, currentRoute } = props
    // ? Pull rootStoreString from __NEXT_DATA__, deserialize, and pass to client-side
    // ? RootStore
    let serializer
    stringify.getSerialize(serializer)
    const rootStore = JSON.parse(rootStoreString, serializer)
    this.rootStore = getRootStore(rootStore)
    tracker.pageView(currentRoute.currentPath)
  }

  componentDidMount() {
    if (IsDevelopment) {
      global.window.rootStore = this.rootStore
      global.window.currentRoute = router.getCurrentRoute()
    }

    this.props.router.events.on('routeChangeComplete', tracker.pageView)
  }

  componentDidUpdate() {
    const { currentUser } = this.rootStore.authStore
    const email = currentUser ? currentUser.email : 'unknown'

    if (email) {
      Sentry.configureScope(scope => {
        scope.setUser({ email })
      })
    }

    if (IsDevelopment) {
      global.window.currentRoute = router.getCurrentRoute()
    }

    window.scrollTo(0, 0)
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, currentRoute } = this.props
    const currentRouteParsed = IsServer ? currentRoute : router.getCurrentRoute()

    return (
      <>
        <NextHead>
          <meta charSet='UTF-8' />
          <title>{SEO.title}</title>
          <meta name='description' content={SEO.description} />
          <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />

          <link rel='apple-touch-icon' sizes='57x57' href='/static/favicon/apple-icon-57x57.png' />
          <link rel='apple-touch-icon' sizes='60x60' href='/static/favicon/apple-icon-60x60.png' />
          <link rel='apple-touch-icon' sizes='72x72' href='/static/favicon/apple-icon-72x72.png' />
          <link rel='apple-touch-icon' sizes='76x76' href='/static/favicon/apple-icon-76x76.png' />
          <link rel='apple-touch-icon' sizes='114x114' href='/static/favicon/apple-icon-114x114.png' />
          <link rel='apple-touch-icon' sizes='120x120' href='/static/favicon/apple-icon-120x120.png' />
          <link rel='apple-touch-icon' sizes='144x144' href='/static/favicon/apple-icon-144x144.png' />
          <link rel='apple-touch-icon' sizes='152x152' href='/static/favicon/apple-icon-152x152.png' />
          <link rel='apple-touch-icon' sizes='180x180' href='/static/favicon/apple-icon-180x180.png' />
          <link rel='icon' type='image/png' sizes='192x192' href='/static/favicon/android-icon-192x192.png' />
          <link rel='icon' type='image/png' sizes='32x32' href='/static/favicon/favicon-32x32.png' />
          <link rel='icon' type='image/png' sizes='96x96' href='/static/favicon/favicon-96x96.png' />
          <link rel='icon' type='image/png' sizes='16x16' href='/static/favicon/favicon-16x16.png' />
          <link rel='manifest' href='/static/favicon/manifest.json' />
          <meta name='msapplication-TileColor' content='#ffffff' />
          <meta name='msapplication-TileImage' content='/static/favicon/ms-icon-144x144.png' />
          <meta name='theme-color' content='#ffffff' />
        </NextHead>
        <Provider rootStore={this.rootStore}>
          <StandardLayout currentRoute={currentRouteParsed} rootStore={this.rootStore}>
            <Component
              {...pageProps}
              currentRoute={currentRouteParsed}
            />
          </StandardLayout>
        </Provider>
      </>
    )
  }
}

const HOCWrapper = (app) => {
  let wrappedApp = app
  const { withTranslations } = CONFIG

  if (withTranslations) {
    wrappedApp = appWithTranslation(wrappedApp)
  }

  return wrappedApp
}

export default withRouter(HOCWrapper(NextJSMobxApp))
