export const CONFIG = {
  withSentry: true,
  withGoogleAnalytics: true,
  withTranslations: true,
  withFbMessenger: true,
  withYandex: false,
  withHotjar: true,
}


export const URL_BASE = 'https://app.airecruiter.online'
export const API_DOMAIN = process.env.AIR_API_DOMAIN || 'https://api.airecruiter.online'
// export const API_DOMAIN = process.env.AIR_API_DOMAIN || 'http://localhost:3000/'
export const API_BASE = `${API_DOMAIN}/api/v1/`
export const SENTRY_DSN = 'https://0fa478689fb04273b50051b8aede5669@o558256.ingest.sentry.io/5691852'
export const GA_CODE = 'G-RHRT5QYGG5'
export const FB_PAGE_ID = '101519192123290'
export const YANDEX_ID = 78462189
export const HOTJAR_ID = 2451854
export const FIREBASE = {
  apiKey: '<api-key>',
  authDomain: '<project-id>.firebaseapp.com',
  databaseURL: 'https://<project-id>.firebaseio.com',
  projectId: '<project-id>',
  storageBucket: '<project-id>.appspot.com',
  messagingSenderId: '<messaging-sender-id>',
  appId: '1:<messaging-sender-id>:web:<token>',
  measurementId: 'G-<measurement-id>',
}
export const UDEMY = {
  clientId: '8qrKxx3TTyxiGvkJ3ivpKlyBjHSJvXuQkybgs3rT',
  clientSecret: 'KNB842rkQfqbGTaW7o0jhvckgIrjYZ7aiu0VmTrqGkYWSBgtTWkWbMEuHkWKAPDRr8Cpbvl3q0dynuTB9NSg7neFcGkMzAaBuiclN5ejt6hbATtRPDxK3aztkBbL7qAm',
}
