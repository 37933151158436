import { css } from '@emotion/react'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const modal = css`
  max-width: 100%;

  ${mqMax.md} {
    padding: 0 5%;
  }
`
