import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { Icon } from '~/components/common'
import useWindowSize from '~/hooks/useWindowSize'
import { BREAKPOINT_SIZES } from '~/styles/mixins/mediaQueries'
import TourSteps from '~/tourSteps'

import * as style from './styles'

const RESPONSIVENESS_BREAKPOINT = 'screenMedium'
const RESPONSIVENESS_MODIFIERS = {
  mobile: '--mobile',
  desktop: '--desktop',
}

const ReactTour = dynamic(
  () => import('reactour'),
  { ssr: false }
)

const getResponsivenessModifier = () => {
  const size = useWindowSize()
  return size.width < BREAKPOINT_SIZES[RESPONSIVENESS_BREAKPOINT].size
    ? RESPONSIVENESS_MODIFIERS.mobile
    : RESPONSIVENESS_MODIFIERS.desktop
}

/**
 * <Describe the Tour component here>
 *
 * @component
 * @usage import Tour from '~/components/Tour'
 * @example
 * <Add an example of how Tour is used here>
 */
const TourComponent = ({ rootStore }) => {
  const { stateStore } = rootStore
  const { isTourOpen, forcedTourStep } = stateStore
  const [currentStep, setCurrentStep] = useState(0)

  const stepsParsed = TourSteps.map(step => {
    const responsivenessModifier = step.isResponsive ? getResponsivenessModifier() : ''
    const childSelector = step.childSelector ? ` [tour-id="${step.childSelector}"]` : ''
    const orderModifier = step.order ? `:nth-of-type(${step.order})` : ''
    const selector = step.rawSelector || `[tour-id="${step.selector}${responsivenessModifier}"]${childSelector}${orderModifier}`

    return {
      ...step,
      selector,
      stepInteraction: Boolean(step.canInteract),
    }
  })

  useEffect(() => {
    if (Boolean(forcedTourStep)) {
      setTimeout(() => {
        setCurrentStep(prevStep => prevStep + forcedTourStep)
        stateStore.forceTourStep(0)
      }, 350)
    }
  }, [forcedTourStep])

  return (
    <ReactTour
      isOpen={isTourOpen}
      onRequestClose={() => stateStore.set({ isTourOpen: false, hasSeenTour: true })}
      rounded={5}
      disableDotsNavigation
      getCurrentStep={setCurrentStep}
      steps={stepsParsed}
      goToStep={currentStep}
      nextButton={stepsParsed[currentStep].shouldDisableNext ? <div /> : null}
      lastStepNextButton={<Icon icon='check' css={style.iconFinish} />}
    />
  )
}

TourComponent.propTypes = {
}

TourComponent.defaultProps = {
}

export default inject('rootStore')(observer(TourComponent))
