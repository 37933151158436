import Service from './Service'

export const SERVICES = {
  signup: {
    type: 'post',
    urlParam: '/signup',
  },
  login: {
    type: 'post',
    urlParam: '/signin',
  },
  getUser: {
    type: 'get',
  },
  getDummyToken: {
    type: 'post',
    urlParam: '/random_user',
  },
  requestOtp: {
    type: 'post',
    urlParam: '/request_otp',
  },
  validatetOtp: {
    type: 'post',
    urlParam: '/validate_otp',
  },
  getLinkedInProfile: {
    type: 'post',
    serviceBase: 'linkedin',
    urlParam: '/profile',
  },
}

/**
 * Instance of Authentication Service.
 *
 * @class
 * @usage `import Authentication from '~/network/Authentication'`
 */
export default class Authentication extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users'
  }
}
