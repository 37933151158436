import { css } from '@emotion/react'
import colors from '~/styles/colors'

export const form = css`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
`

export const label = css`
  font-size: 0.8rem;
  color: ${colors.greyDark};
  margin-bottom: 0.5rem;
  transition: 0.2s;
`

export const inputMinimal = hasError => css`
  border: none;
  outline: none;
  border-bottom: 0.01rem solid ${colors.greyDark};
  padding-bottom: 0.5rem;
  text-align: center;
  transition: 0.2s;
  
  ${hasError && `
    border-color: ${colors.red};
  `};

  &:focus {
    border-color: ${colors.blue};
    & + label {
      color: ${colors.blue};
    }
  }
`

export const inputOTP = css`
  width: 2rem;
  margin: 0 0.5rem;
  font-size: 1.5rem;
`

export const error = css`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: ${colors.red};
`
