/**
 * Predefined characters that are used by regex patterns.
 *
 * @constant
 */
const CHARS = {
  CUSTOM_START_PARAM: ':',
  CUSTOM_END_PARAM: '/',
  NEXTJS_START_PARAM: '[',
  NEXTJS_END_PARAM: ']',
}

/**
 * Predefined regex pattern.
 *
 * @constant
 */
const patterns = {
  paramsStrict: `(${CHARS.CUSTOM_START_PARAM})(.*?)(?=$|${CHARS.CUSTOM_END_PARAM})`,
  firstParamAndOnward: `/${CHARS.CUSTOM_START_PARAM}(.*?)*`,
  firstNextJsParamAndOnward: `/\\${CHARS.NEXTJS_START_PARAM}(.*?)*`,
}

/**
 * Generate a new regex pattern based on predefined patterns.
 *
 * @function
 * @param {string} pattern - The pattern or pattern chosen
 * @returns {regex}
 */
const regex = (pattern) => {
  return new RegExp(pattern, 'g')
}

regex.patterns = patterns
export default regex
