import Store from './Store'
import { action, observable, computed, toJS } from 'mobx'
import { isEmpty, isNil } from 'lodash'
import { CookieHelper } from '~/resources/helpers'
import Authentication from '~/network/Authentication'

class AuthStore extends Store {
  @observable _authToken
  @observable _linkedInToken
  @observable _currentUser

  constructor(initialData) {
    super(initialData)

    if (initialData) {
      let dataSource = initialData

      if (initialData.cookie) {
        dataSource = initialData.cookie
      }

      this._authToken = dataSource._authToken
      this._currentUser = dataSource
    }

    if (this.authToken) {
      this.refreshUser({ detailed: true })
    }
  }

  service() {
    return new Authentication({ authStore: this })
  }

  refreshUser(params) {
    this.storeApiResponse({
      apiName: 'getUser',
      observableName: '_currentUser',
      params,
    })
  }

  async generateDummyToken() {
    const resp = await this.api().getDummyToken()
    const authToken = resp.data.auth_token
    if (!this.isLoggedIn) {
      this.setCurrentUser({ authToken })
    }
    return resp
  }

  @action setCurrentUser(user) {
    const { auth_token, authToken, ...userInfo } = user
    this._currentUser = userInfo
    const authTokenParsed = auth_token || authToken
    this.setAuthToken(authTokenParsed)

    CookieHelper.modifyCookieData({
      tokenData: {
        _authToken: authTokenParsed,
        _currentUser: { id: userInfo.id },
      },
    })
  }

  @action async login(params) { // todo: Cleanup if not being used
    const resp = await this.api().login(params)

    if (resp.success) {
      await this.setCurrentUser(resp.data.user)
    }

    return resp
  }

  @action logout() {
    CookieHelper.clearCookieData()
    this._currentUser = {}
    this._authToken = null
    this.rootStore.resumesStore.onLogout()
    this.rootStore.titlesStore.onLogout()
  }

  @action setAuthToken(value) {
    this._authToken = value
  }

  @action setLinkedInToken(value) {
    this._linkedInToken = value
  }

  @computed get isLoggedIn() {
    return !isEmpty(this._authToken)
  }

  @computed get authToken() {
    return toJS(this._authToken)
  }

  @computed get linkedInToken() {
    return toJS(this._linkedInToken)
  }

  @computed get hasUserInfo() {
    return !isEmpty(this.userInfo) && !isNil(this.userInfo.id) // ? `id` can be replaced with any key should only exist when the user info has been queried
  }

  @computed get currentUser() {
    return toJS(this._currentUser)
  }
}

export default AuthStore
