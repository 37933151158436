import { css } from '@emotion/react'
import { mqMin } from '~/styles/mixins/mediaQueries'
import colors from '~/styles/colors'

const THEMES = {
  light: {
    background: colors.white,
    color: colors.blue,
    fill: colors.blue,
  },
  dark: {
    background: colors.blue,
    color: colors.white,
    fill: colors.white,
  },
}

export const anchor = css`
  text-decoration: none !important;
`

export const button = ({ isPill, theme }) => css`
  position: relative;
  margin: 0;
  
  ${!isPill && `
    &:not(.rs-btn-link) {
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 0.3rem;
      box-shadow: 0.2rem 0.2rem 0.4rem ${colors.shadow};
      color: ${THEMES[theme].color};
      fill: ${THEMES[theme].fill};
      background: ${THEMES[theme].background};

      ${mqMin.md} {
        font-size: 0.85rem;
        padding: 1rem 2.25rem;
      }
    }
  `};

  ${isPill && `
    // color: inherit;
    // background: inherit;
    text-transform: none;
    border-radius: 3rem;
    font-size: 0.75rem;
    font-weight: normal;
    padding: 0 0.7rem;
    box-shadow: none;
  `};
`

export const icon = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  fill: inherit;
`

export const buttonContainer = css`
  display: flex;
  align-items: center;

  > a {
    display: flex;
    width: 120%;
  }

  > button, a {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`
