import React from 'react'
import PropTypes from 'prop-types'

import { Input } from 'rsuite'

import * as style from './styles'

const TextAreaComponent = ({ onChange, isLoading, isDisabled, ...extra }) => {
  return (
    <Input
      componentClass='textarea'
      fluid
      rows={3}
      style={{ resize: 'auto' }}
      loading={isLoading}
      onChange={value => onChange(value)}
      disabled={isDisabled}
      css={style.textArea}
      {...extra}
    />
  )
}

TextAreaComponent.propTypes = {
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

TextAreaComponent.defaultProps = {
  onChange: () => {},
  isLoading: false,
  isDisabled: false,
}

export default TextAreaComponent
