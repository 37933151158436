import React from 'react'
import PropTypes from 'prop-types'

import InputOtp from './InputOtp'

import * as style from './styles'

const Inputs = ({ type, onChange, onEnter, isDisabled, error, ...extra }) => {
  const handleKeyPress = ({ key }) => {
    if (key.toLowerCase() === 'enter') {
      onEnter()
    }
  }

  if (type) {
    const typeSplit = type.split('|')
    const typeParsed = typeSplit[0]
    const typeLength = typeSplit[1] ? Number(typeSplit[1]) : 1

    if (typeParsed === 'otp') {
      return (
        <InputOtp
          typeLength={typeLength}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          isDisabled={isDisabled}
          error={error}
          type={type}
          {...extra}
        />
      )
    }
  }

  return (
    <input
      onChange={ev => onChange(ev.target.value)}
      disabled={isDisabled}
      css={style.inputMinimal(error)}
      onKeyPress={handleKeyPress}
      type={type}
      {...extra}
    />
  )
}

/**
 * <Describe the InputMinimalistic component here>
 *
 * @component
 * @usage import InputMinimalistic from '~/components/InputMinimalistic'
 * @example
 * <Add an example of how InputMinimalistic is used here>
 */
const InputMinimalistic = ({ label, type, onChange, onEnter, isDisabled, cssContainer, error, ...extra }) => {
  return (
    <div css={[style.form, cssContainer]}>
      {error && <p css={style.error}>{error}</p>}
      <Inputs
        type={type}
        onChange={onChange}
        onEnter={onEnter}
        isDisabled={isDisabled}
        error={error}
        {...extra}
      />
      <label css={style.label}>
        {label}
      </label>
    </div>
  )
}

InputMinimalistic.propTypes = {
  label: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  cssContainer: PropTypes.object,
  error: PropTypes.string,
}

InputMinimalistic.defaultProps = {
  label: '',
  type: '',
  onChange: () => {},
  isDisabled: false,
  cssContainer: {},
  error: '',
}

export default InputMinimalistic
