import { css } from '@emotion/react'
import { mqMax } from '~/styles/mixins/mediaQueries'
import { headerHeight, zIndexes } from '~/styles/constants'

export const layout = isLayoutLocked => css`
  position: relative;
  margin-top: ${headerHeight};
  ${isLayoutLocked && `
    overflow: hidden;
    height: 100vh;
  `};
`

export const loaderSync = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5rem;
  left: 0.5rem;
  z-index: ${zIndexes.notficiations};

  > div {
    height: 3rem;
    width: 3rem;
    margin-right: 0.75rem;
    margin-left: 0;
  }

  > span {
    width: 10rem;
  }

  ${mqMax.md} {
    flex-direction: row-reverse;
    top: unset;
    left: unset;
    bottom: 4rem;
    right: 1rem;

    > div {
      height: 2rem;
      width: 2rem;
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

`
