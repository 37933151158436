import React from 'react'
import PropTypes from 'prop-types'
import { router, Link } from '~/lib/router'
import tracker from '~/lib/tracker'

import { Icon } from '~/components/common'
import Logo from '~/public/static/logo.svg'

import * as style from './styles'

const trackClick = (button) => {
  tracker.event('Click Header', { button })
}

/**
 * Header / Navbar
 *
 * @component
 * @usage `import Header from '~/components/Header'`
 */
const Header = ({ isLoggedIn, currentRoute, onClickEditor, onClickLogin, onClickLogout, onClickTour }) => {
  const isOnLandingPage = currentRoute.key === 'home'

  return (
    <header css={style.header(isOnLandingPage)}>
      <Icon icon='bars' css={style.mobileMenu} />
      <Link css={style.title} href={router.routes.home}>
        <Logo css={style.titleLogo} />
        AIRecruiter
      </Link>
      <div css={style.headerMenu}>
        {
          isLoggedIn && (
            <span css={style.currencyCounter}>15 <Icon icon='coins' /></span>
          )
        }
        {
          isLoggedIn && (
            <span
              css={style.menuItem}
              onClick={() => {
                trackClick('Editor')
                onClickEditor()
              }}
            >
              Editor
            </span>
          )
        }
        {
            isLoggedIn && (
              <span css={style.menuItem}
                onClick={() => {
                  trackClick('Tour')
                  onClickTour()
                }}
              >
                Tour
              </span>
        )}
        <span css={style.menuItem}>About</span>
        {
          isLoggedIn ? (
            <span
              css={style.menuItem}
              onClick={() => {
                trackClick('Logout')
                onClickLogout()
              }}
            >
              Logout
            </span>
          ) : (
            <span
              css={style.menuItem}
              onClick={() => {
                trackClick('Login')
                onClickLogin()
              }}
            >
              Login
            </span>
          )
        }
      </div>
    </header>
  )
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool,
  onClickLogin: PropTypes.func,
  onClickLogout: PropTypes.func,
}

Header.defaultProps = {
  isLoggedIn: false,
  onClickLogin: () => {},
  onClickLogout: () => {},
}

export default Header
