import { API_DOMAIN } from '~/global.config'

export const SECTIONS_UNENHANCEABLE = [
  'education',
  'certifications',
  'skills',
]

export const SECTIONS_ORDERED = [
  'summary',
  'accomplishments',
  'experience',
  'education',
  'certifications',
  'skills',
]

export const SECTIONS_ORGANIZED_BY_JOB = [
  'experience',
]

export const TITLES_EXPECTED_NUMBER = 3

export const RATINGS_TYPES = [
  {
    code: 'vote_up',
    icon: 'thumbsUp',
  },
  {
    code: 'vote_down',
    icon: 'thumbsDown',
  },
]

export const OTP_LENGTH = 6

export const EXPORT_URL = (guid) => `${API_DOMAIN}/resume.pdf?guid=${guid}`

export const EMPTY_SETS = {
  experience: {
    employer: '',
    position: '',
    start_date: '',
    end_date: '',
    texts: [],
  },
  education: {
    text: '',
  },
  certifications: {
    text: '',
  },
}
