import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { transparentize } from 'polished'
import { zIndexes } from '~/styles/constants'

export const LoaderFullScreen = css`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${transparentize(0.15, colors.black)};
  color: ${colors.white};
  z-index: ${zIndexes.godmode};

  > span {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`

export const loader = css`
  font-size: 5rem;
`
