import { css } from '@emotion/react'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const title = css`
	font-size: 3rem;
	text-transform: uppercase;
	text-align: center;
	
	${mqMax.md} {
		font-size: 2rem;
	}
`
