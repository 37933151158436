export default {
  home: '/',
  logout: '/logout',
  login: '/login',
  scores: '/scores',
  positions: '/positions',
  edit: '/edit/:slug',
  oAuth: '/oauth',
  review: '/review',
  // * [reserved] [add_new_route]
}
