import Service from './Service'

export const SERVICES = {
  getList: {
    type: 'get',
  },
  create: {
    type: 'post',
  },
  upload: {
    type: 'postWithFormData',
    urlParam: '/upload_file',
  },
  getCurrent: {
    type: 'get',
    urlParam: '/current',
  },
  getSuggestedTitles: {
    type: 'get',
    urlParam: '/:id/suggested_titles',
  },
  getRecommendations: {
    type: 'get',
    urlParam: '/:id/recommendations',
  },
  getCourses: {
    type: 'get',
    urlParam: '/:id/courses',
  },
}

/**
 * Instance of Resumes Service.
 *
 * @class
 * @usage `import ResumesService from '~/network/ResumesService'`
 */
export default class ResumesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users/resumes'
  }
}
