import React from 'react'
import PropTypes from 'prop-types'

import Loader from '~/components/Loader'

import * as style from './styles'

/**
 * <Describe the LoaderFullScreen component here>
 *
 * @component
 * @usage import LoaderFullScreen from '~/components/LoaderFullScreen'
 * @example
 * <Add an example of how LoaderFullScreen is used here>
 */
const LoaderFullScreen = (props) => {
  return (
    <div css={style.LoaderFullScreen}>
      <span>
        Scoring your resume...
      </span>
      <Loader css={style.loader} />
    </div>
  )
}

LoaderFullScreen.propTypes = {
}

LoaderFullScreen.defaultProps = {
}

export default LoaderFullScreen
