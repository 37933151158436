import Cookies from 'js-cookie'
import cookie from 'cookie'
import IsServer from '~/resources/utils/isServer'

const AUTH_KEY = 'AUTH_COOKIE_KEY'
const EXPIRY_TIME_IN_DAYS = 360

/**
 * Function to add cookie.
 * @function
 * @usage `import { addCookie } from '~/helpers/cookieHelper'`
 * @param {object} name - Name of the cookie
 * @param {string} value - Value of the cookie
 * @param {string} days - Validity period of cookie in days
 */
const addCookie = ({ name = AUTH_KEY, value, days = 0, expires = '' }) => {
  let expiresAt = `; expires=${expires}`
  let validityInDays = days

  if (expiresAt === '' && validityInDays === 0) {
    validityInDays = EXPIRY_TIME_IN_DAYS
  }

  if (validityInDays !== 0) {
    const date = new Date()
    date.setTime(date.getTime() + validityInDays * 24 * 60 * 60 * 1000)
    expiresAt = `; expires=${date.toGMTString()}`
  }

  if (!IsServer) {
    document.cookie = `${name}=${value}${expiresAt};path=/`
  }
}

/**
 * Function to parse raw cookie data.
 * @function
 * @usage `import { getCookieData } from '~/helpers/cookieHelper'`
 * @param {string} cookieData - Cookie value
 * @returns {object}
 */
const getCookieData = cookieData => {
  if (!cookieData) {
    return {}
  }

  const theCookie = cookie.parse(cookieData)[AUTH_KEY]

  try {
    return theCookie ? JSON.parse(theCookie) : {}
  } catch (err) {
    return theCookie
  }
}

/**
 * Function to clear cookie.
 * @function
 * @usage `import { clearCookieData } from '~/helpers/cookieHelper'`
 * @param {string} Cookie name to clear
 */
const clearCookieData = (cookieName = AUTH_KEY) => {
  Cookies.remove(cookieName)
}

/**
 * Function to modify cookie values.
 * @function
 * @usage `import { modifyCookieData } from '~/helpers/cookieHelper'`
 * @param {JSON} cookieData - Full cookie data
 */
const modifyCookieData = ({ cookieData, ...extra }) => {
  addCookie({
    value: cookieData ? JSON.stringify(cookieData) : {},
    ...extra,
  })
}

export default { addCookie, getCookieData, clearCookieData, modifyCookieData }
