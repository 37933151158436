import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { numberToTime, timeToNumber } from '~/helpers/dateHelper'

/**
 * <Describe the Countdown component here>
 *
 * @component
 * @usage import Countdown from '~/components/Countdown'
 * @example
 * <Add an example of how Countdown is used here>
 */
const Countdown = ({ time, onComplete }) => {
  const [timer, setTimer] = useState(time)
  const [timerInterval, setTimerInterval] = useState(null)

  const stopTimer = () => {
    clearTimeout(timerInterval)
  }

  const startTimer = () => {
    setTimerInterval(
      setInterval(() => {
        let newTimer
        setTimer(timer => {
          newTimer = timer - 1
          return newTimer
        })

        if (timeToNumber(newTimer) <= 0 && time !== 0) {
          stopTimer()
          setTimer(0)
          onComplete()
        }
      }, 1000)
    )
  }

  useEffect(() => {
    setTimer(time)
    stopTimer()
    startTimer()
  }, [time])

  return (
    <>{numberToTime(timer)}</>
  )
}

Countdown.propTypes = {
  time: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
}

Countdown.defaultProps = {
  time: 10,
  onComplete: () => {},
}

export default Countdown
