import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from '~/styles/colors'
import { headerHeight, zIndexes } from '~/styles/constants'
import { mqMin, mqMax } from '~/styles/mixins/mediaQueries'

export const header = isOnLandingPage => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background: ${isOnLandingPage ? colors.landingPageLightBlue : colors.blue};
  color: ${colors.white};
  height: ${headerHeight};
  padding: 0 10%;
  z-index: ${zIndexes.header};

  ${isOnLandingPage && `
    box-shadow: 0rem 0.5rem 1rem 0rem ${transparentize(0.85, colors.black)};
  `};

  ${mqMax.md} {
    padding: 0 5%;
  }
`

export const title = css`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none !important;
  color: ${colors.white} !important;
`

export const titleLogo = css`
  margin-right: 1rem;
  height: calc(${headerHeight} - 2.5rem);

  path {
    fill: ${colors.white};
  }
`

export const headerMenu = css`
  display: flex;
  align-items: center;

  > span {
    font-size: 1rem;
    font-weight: bold;

    + span {
      margin-left: 2rem;
    }
  }
`

export const mobileMenu = css`
  font-size: 1.5rem;

  ${mqMin.md} {
    display: none;
  }
`

export const menuItem = css`
  cursor: pointer;

  ${mqMax.md} {
    display: none;
  }
`

export const currencyCounter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  color: ${colors.blue};
  padding: 0.1rem 0.4rem;
  border-radius: 0.3rem;
  width: auto;

  > svg {
    margin-left: 0.5rem;
  }
`
