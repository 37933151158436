import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Subtitle component here>
 *
 * @component
 * @usage import Subtitle from '~/components/Subtitle'
 * @example
 * <Add an example of how Subtitle is used here>
 */
const Subtitle = (props) => <h5 css={style.subtitle} {...props} />

Subtitle.propTypes = {
}

Subtitle.defaultProps = {
}

export default Subtitle
