import Store from './Store'
import { observable, action, computed, toJS } from 'mobx'

class StateStore extends Store {
  @observable _isLoginOpen = false
  @observable _isLoading = false
  @observable _onLoginSuccess = () => {}
  @observable _isTourOpen = false
  @observable _hasSeenTour = true
  @observable _forcedTourStep = 0
  @observable _syncStatus = ''

  constructor(initialData = {}) {
    super(initialData)
  }

  @action set(stateObject) {
    const stateKeys = Object.keys(stateObject)

    stateKeys.forEach(key => {
      const stateName = `_${key}`
      const stateValue = stateObject[key]
      this[stateName] = stateValue
    })
  }

  @action forceTourStep(step = +1) {
    if (this.isTourOpen) {
      this._forcedTourStep = step
    }
  }

  @action setSyncStatus(status = '') { // ? Statuses: pending | success | failure
    this._syncStatus = status

    if (status !== 'pending' && status !== '') {
      setTimeout(() => { this.setSyncStatus() }, 5000)
    }
  }

  @computed get isLoginOpen() {
    return toJS(this._isLoginOpen)
  }

  @computed get onLoginSuccess() {
    return this._onLoginSuccess
  }

  @computed get isLoading() {
    return toJS(this._isLoading)
  }

  @computed get isTourOpen() {
    return toJS(this._isTourOpen)
  }

  @computed get hasSeenTour() {
    return toJS(this._hasSeenTour)
  }

  @computed get forcedTourStep() {
    return toJS(this._forcedTourStep)
  }

  @computed get syncStatus() {
    return toJS(this._syncStatus)
  }
}

export default StateStore
