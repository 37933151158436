import axios from 'axios'
import { RouteHelper, FormDataHelper } from '~/resources/helpers'

const createErrorResponse = (response = { data: {} }) => {
  return { data: { success: false, message: response.data.message, data: {} } }
}

/**
 * Api service is used to send http requests
 */
export default class Api {
  constructor({ authStore, axiosConfig = {}, extraHeaders = {} }) {
    this.authStore = authStore
    this.extraHeaders = extraHeaders

    Object.keys(axiosConfig).forEach(configKey => {
      const configValue = axiosConfig[configKey]
      axios.defaults[configKey] = configValue
    })
  }

  /**
   * To send http get request
   * @function
   * @async
   * @param url - get request url
   * @param params - query parameters of the get url
   * @param headers - request headers
   * @param extra - extra configuration parameters
   * */
  async get(url, { params, headers, ...extra } = {}) {

    let result
    const config = {
      ...extra,
      method: 'get',
      headers: { ...this.createHeaders(), ...headers },
      url: RouteHelper.convertToQueryString(url, params),
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }

  /**
   * To send http post request
   * @function
   * @async
   * @param url - post request url
   * @param params - post request data
   * @param headers - request headers
   * */
  async post(url, { params, headers } = {}) {
    let result
    const config = {
      method: 'post',
      headers: { ...this.createHeaders(), ...headers },
      url: url,
      data: params,
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }

  /**
   * To send http put request
   * @function
   * @async
   * @param url - put request url
   * @param params - put request data
   * @param headers - request headers
   * */
  async put(url, { params, headers } = {}) {
    let result
    const config = {
      method: 'put',
      headers: { ...this.createHeaders(), ...headers },
      url: url,
      data: params,
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }

  /**
   * To send http patch request
   * @function
   * @async
   * @param url - patch request url
   * @param params - patch request data
   * @param headers - request headers
   * */
  async patch(url, { params, headers } = {}) {
    let result
    const config = {
      method: 'patch',
      headers: { ...this.createHeaders(), ...headers },
      url: url,
      data: params,
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }

  /**
   * To send http delete request
   * @function
   * @async
   * @param url - delete request url
   * @param params - delete request data
   * @param headers - request headers
   * */
  async delete(url, { params, headers } = {}) {
    let result
    const config = {
      method: 'delete',
      headers: { ...this.createHeaders(), ...headers },
      url: url,
      data: params,
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }

  /**
   * To send http post request with form data
   * @function
   * @async
   * @param url - post request url
   * @param params - form data to be sent
   * @param headers - request headers
   * */
  async postWithFormData(url, { params, headers } = {}) {
    let result
    const config = {
      method: 'post',
      headers: { ...this.createHeaders(), ...headers },
      url: url,
      data: FormDataHelper.generateFormData(params),
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }


  /**
   * To send http put request with form data
   * @function
   * @async
   * @param url - patch request url
   * @param params - form data to be sent
   * @param headers - request headers
   * */
  async putWithFormData(url, { params, headers } = {}) {
    let result
    const config = {
      method: 'put',
      headers: { ...this.createHeaders(), ...headers },
      url: url,
      data: FormDataHelper.generateFormData(params),
    }
    await axios(config)
      .then(function (response) {
        result = response.data
      })
      .catch(function (response) {
        result = createErrorResponse(response.response)
      })
    return result
  }

  /**
   *
   * create default headers for the api requests
   */
  createHeaders() {
    const headers = {}
    headers['Content-Type'] = 'application/json'
    let authToken = null
    if (this.authStore) {
      authToken = this.authStore.authToken
    }
    if (authToken) {
      headers.Authorization = authToken
    }
    Object.keys(this.extraHeaders).forEach(headerKey => {
      const headerValue = this.extraHeaders[headerKey]
      const authStoreHeaderValue = this.authStore[headerValue]
      if (authStoreHeaderValue) {
        headers[headerKey] = authStoreHeaderValue
      }
    })
    return headers
  }
}


