import React from 'react'
import PropTypes from 'prop-types'

import { DatePicker as RSuiteDatePicker } from 'rsuite'
import { formatDate } from '~/helpers/dateHelper'

import * as style from './styles'

/**
 * <Describe the DatePicker component here>
 *
 * @component
 * @usage import DatePicker from '~/components/DatePicker'
 * @example
 * <Add an example of how DatePicker is used here>
 */
const DatePicker = ({ format, onChange, ...extra }) => {
  return (
    <RSuiteDatePicker
      format={format}
      css={style.datePicker}
      onChange={value => format && value ? onChange(formatDate(value, format)) : onChange(value)}
      {...extra}
    />
  )
}

DatePicker.propTypes = {
}

DatePicker.defaultProps = {
}

export default DatePicker
