import Store from './Store'
import DevicesService from '~/network/DevicesService.js'
import { observable, action, computed, toJS } from 'mobx'

class DevicesStore extends Store {
  @observable _observableOne = '';

  constructor(initialData = {}) {
    super(initialData)
  }

  service() {
    return new DevicesService(this.rootStore)
  }

  @action setObservableOne(value) {
    this._observableOne = value
  }

  @computed get observableOne() {
    return toJS(this._observableOne)
  }

  returnSomething() {
    return 'Something from DevicesStore'
  }
}

export default DevicesStore
