import React from 'react'
import PropTypes from 'prop-types'
import HttpStatus from 'http-status-codes'
import { isEmpty, trim, isObject } from 'lodash'
import { Regex, IsServer } from '~/resources/utils'

const buildRoute = ({ href, params }) => {
  const formattedHref = isObject(href) ? href.path : href

  let template = formattedHref
  let actual = formattedHref

  if (isEmpty(params)) {
    return { templatePath: template, actualPath: actual }
  }

  template = formattedHref.replace(Regex(Regex.patterns.paramsStrict), variable => {
    const variableTrimmed = trim(variable, ':')
    return `[${variableTrimmed}]`
  })

  actual = formattedHref.replace(Regex(Regex.patterns.paramsStrict), variable => {
    const variableTrimmed = trim(variable, ':')
    return `${params[variableTrimmed]}`
  })

  return { templatePath: template, actualPath: actual }
}

const findBasePath = (path, exp = Regex.patterns.firstParamAndOnward) => {
  return path.replace(Regex(exp), '')
}

const provideRoutes = routes => {
  const routesProvided = {}
  const routeKeys = Object.keys(routes)

  routeKeys.forEach(routeKey => {
    let routeValue = routes[routeKey]

    if (!isObject(routeValue)) {
      routeValue = { path: routeValue }
    }

    const basePath = findBasePath(routeValue.path)
    routesProvided[routeKey] = {
      ...routeValue,
      basePath,
    }
  })

  return routesProvided
}

const routerReplace = (href = '', params = {}, ctx = {}, NextRouter) => {
  const { templatePath, actualPath } = buildRoute({ href, params })

  if (ctx.res) {
    ctx.res.writeHead(HttpStatus.MOVED_TEMPORARILY, {
      Location: actualPath,
    })
    ctx.res.end()
  } else {
    NextRouter.replace(templatePath, actualPath)
  }
}

const routerPush = (href = '', params = {}, ctx = {}, NextRouter) => {
  const { templatePath, actualPath } = buildRoute({ href, params })

  if (ctx.res) {
    ctx.res.writeHead(HttpStatus.MOVED_TEMPORARILY, {
      Location: actualPath,
    })
    ctx.res.end()
  } else {
    NextRouter.push(templatePath, actualPath)
  }
}

/**
 * Function to handle app routes or paths by url.
 *
 * @function
 * @param {object} baseRoutes - absolute app routes | Example: { home: '/home' }
 * @returns {object} - | Example: { '/home': 'home' }
 */
const getRoutesByPath = routesProvided => {
  const reversedObject = {}
  Object.keys(routesProvided).forEach(routeKey => {
    const routeValue = routesProvided[routeKey]
    reversedObject[routeValue.basePath] = {
      key: routeKey,
      ...routeValue,
    }
  })

  return reversedObject
}

/**
 * Function to get the current route and related parameters.
 *
 * @param {object} routesByPath - Object of routes organized by path
 * @param {object} [ctx={}]
 */
const getCurrentRoute = (routesByPath, ctx = {}, NextRouter) => {
  if (!isEmpty(ctx)) {
    const basePath = findBasePath(ctx.pathname, Regex.patterns.firstNextJsParamAndOnward)
    return {
      currentPath: ctx.asPath,
      query: ctx.query,
      ...routesByPath[basePath],
    }
  }

  if (!IsServer) {
    const basePath = findBasePath(NextRouter.route, Regex.patterns.firstNextJsParamAndOnward)
    return {
      currentPath: NextRouter.asPath,
      query: NextRouter.query,
      ...routesByPath[basePath],
    }
  }

  return {}
}

const Link = ({ href, params, children, ...extra }, NextLink) => {
  const { templatePath, actualPath } = buildRoute({ href, params })

  return (
    <NextLink href={templatePath} as={actualPath}>
      <a {...extra}>{children}</a>
    </NextLink>
  )
}

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  params: PropTypes.object,
}

Link.defaultProps = {
  href: '',
  params: {},
}

export default {
  buildRoute,
  findBasePath,
  provideRoutes,
  routerReplace,
  routerPush,
  getRoutesByPath,
  getCurrentRoute,
  Link,
}
