import { isEmpty } from 'lodash'
import qs from 'qs'

const getPath = path => {
  return path && path.split('?')[0]
}

/**
 * Function to convert query string to URL params.
 * @function
 * @usage `import { convertToURLParams } from '~/helpers/routeHelper'`
 * @param {string} url - Path in the following format: `/page?urlP1=1&urlP2=2`
 * @returns {object}
 */
const convertToURLParams = url => {
  if (!url) {
    return null
  }

  const search = url.split('?')[1]
  return qs.parse(search)
}

/**
 * Function to convert URL and params to a query string.
 * @function
 * @usage `import { convertToQueryString } from '~/helpers/routeHelper'`
 * @param {string} path - Path in the following format: `/page`
 * @param {object} params - Object of params to append to the URL
 * @returns {string}
 */
const convertToQueryString = (path, params) => {
  if (!path) {
    return ''
  }

  const convertedPath = getPath(path)

  if (params && !isEmpty(params)) {
    const queryString = Object.keys(params)
      .filter(key => params[key] || !isEmpty(params[key]))
      .map(key => {
        if (Array.isArray(params[key])) {
          return params[key].map(value => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`).join('&')
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      })
      .join('&')
    return `${convertedPath}${isEmpty(queryString) ? '' : '?'}${queryString}`
  }
  return convertedPath
}

export default { convertToURLParams, convertToQueryString }
