import Service from './Service'

export const SERVICES = {
  updateAttachment: {
    type: 'put',
    urlParam: '/:guid',
  },
  getLatestAttachment: {
    type: 'get',
    urlParam: '/newest_pdf',
  },
}

/**
 * Instance of Attachments Service.
 *
 * @class
 * @usage `import AttachmentsService from '~/network/AttachmentsService'`
 */
export default class AttachmentsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'attachments'
  }
}
