import { css } from '@emotion/react'
import { zIndexes } from '~/styles/constants'
import colors from '~/styles/colors'

export const otpLoginContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: ${zIndexes.overlay};
  background: ${colors.white};
  height: 100%;
  width: 100%;
  padding: 2rem;
`

export const iconClose = css`
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
`

export const title = css`
  text-align: center;
`

export const inputEmail = css`
  width: 20rem;
`

export const inputEmailContainer = css`
  margin: 2.5rem 0;
`

export const email = css`
  display: block;
  color: ${colors.blue};
  text-align: center;
`

export const button = css`
  padding: 0.75rem 6rem;
`

export const buttonValidate = css`
  margin-bottom: 1rem;
`

export const buttonResend = css`
  font-size: 0.8rem;
  padding: 0;
`
