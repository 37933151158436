import React from 'react'
import PropTypes from 'prop-types'
import NextHead from 'next/head'
import {
  SEO as defaultSEO,
  OG as defaultOG,
  TWITTER as defaultTwitter,
} from '~/meta.config'
import { Meta } from '~/resources/components'


/**
 * Component to render all necessary SEO tags in the page.
 *
 * @component
 * @usage `import { SEO }, Meta from '~/components/Meta'`
 * @example
 * <Meta
 *  allTags={{
 *    ...SEO
 *    title: 'Changed Title',
 *    description: 'Changed Description',
 *  }}
 * />
 */
const MetaComponent = ({ isHead, allTags, general, twitter, openGraph }) => {
  return (
    <Meta
      isHead={isHead}
      HeadComponent={NextHead}
      allTags={allTags}
      general={{ ...defaultSEO, ...general }}
      twitter={{ ...defaultTwitter, ...twitter }}
      openGraph={{ ...defaultOG, ...openGraph }}
    />
  )
}

MetaComponent.propTypes = {
  /**
  * Whether or not these tags should be wrapped in the <head /> tag // ? Should be false if component is inside of HTML <head />
  */
  isHead: PropTypes.bool,
  /**
   * SEO properties that apply to all SEO tags // ? [Recommended]
   */
  allTags: PropTypes.object,
  /**
  * SEO properties that are seen in search engines but do not affect other SEO tags
  */
  general: PropTypes.object,
  /**
  * SEO properties specific to Twitter only
  */
  twitter: PropTypes.object,
  /**
  * SEO properties specific to OpenGraph (Facebook) only
  */
  openGraph: PropTypes.object,
}

MetaComponent.defaultProps = {
  isHead: true,
  allTags: {},
  general: {},
  twitter: {},
  openGraph: {},
}

export { defaultSEO as SEO }
export default MetaComponent
