import React from 'react'
import PropTypes from 'prop-types'

import { router, Link } from '~/lib/router'

import * as style from './styles'

/**
 * Sidebar
 *
 * @component
 * @usage `import Sidebar from '~/components/Sidebar'`
 */
const Sidebar = ({ currentRoute }) => {
  const { routes } = router
  const { key } = currentRoute

  return (
    <aside css={style.sidebar}>
      {
        Object.keys(routes).map(routeKey => {
          const isCurrentRoute = key === routeKey

          return (
            <span key={`route-${routeKey}`}>
              {isCurrentRoute ? '👉 ' : ''}
              <Link href={routes[routeKey].path}>
                {routeKey}
              </Link>
              {isCurrentRoute ? ' 👈' : ''}
              <br />
            </span>
          )
        })
      }
    </aside>
  )
}

Sidebar.propTypes = {
  currentRoute: PropTypes.object,
}

Sidebar.defaultProps = {
  currentRoute: {},
}

export default Sidebar
