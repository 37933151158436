import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '~/components/common'
import InputMinimalistic from '~/components/InputMinimalistic'

import * as style from '../../styles'

const ScreenEmailInput = ({ onChange, isLoading, onConfirm, inputError }) => (
  <>
    <b css={style.title}>
      We will send you a One-Time Password to verify your account
    </b>
    <InputMinimalistic
      label='Enter your email'
      type='email'
      name='email'
      autoComplete='on'
      onChange={onChange}
      onEnter={onConfirm}
      isDisabled={isLoading}
      css={style.inputEmail}
      cssContainer={style.inputEmailContainer}
      error={inputError}
    />
    <Button
      theme='dark'
      isLoading={isLoading}
      css={style.button}
      onClick={onConfirm}
    >
      Get OTP
    </Button>
  </>
)

ScreenEmailInput.propTypes = {
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  inputError: PropTypes.string,
}

ScreenEmailInput.defaultProps = {
  onChange: () => {},
  onConfirm: () => {},
  isLoading: false,
  inputError: '',
}

export default ScreenEmailInput