import Service from './Service'

export const SERVICES = {
  getAllDevices: {
    type: 'get',
  },
  add: {
    type: 'post',
  },
  updateNotifications: {
    type: 'put',
    urlParam: '/notifications',
  },
  delete: {
    type: 'delete',
  },
}

/**
 * Instance of Devices Service.
 *
 * @class
 * @usage `import DevicesService from '~/network/DevicesService'`
 */
export default class DevicesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users/devices'
  }
}
