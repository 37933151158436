import Service from './Service'

export const SERVICES = {
  rate: {
    type: 'post',
    urlParam: '/:id/:rating',
  },
  markUsed: {
    type: 'post',
    urlParam: '/:id/use',
  },
}

/**
 * Instance of Recommendations Service.
 *
 * @class
 * @usage `import RecommendationsService from '~/network/RecommendationsService'`
 */
export default class RecommendationsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'recommendations'
  }
}
