import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'rsuite'

import * as style from './styles'

/**
 * <Describe the Modal component here>
 *
 * @component
 * @usage import Modal from '~/components/Modal'
 * @example
 * <Add an example of how Modal is used here>
 */
const ModalComponent = ({ children, isOpen, withBackdrop, onClose, ...extra }) => {
  return (
    <Modal
      show={isOpen}
      backdrop={withBackdrop}
      onHide={onClose}
      css={style.modal}
      {...extra}
    >
      {children}
    </Modal>
  )
}

ModalComponent.Header = Modal.Header
ModalComponent.Title = Modal.Title
ModalComponent.Body = Modal.Body
ModalComponent.Footer = Modal.Footer

ModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  withBackdrop: PropTypes.bool,
  onClose: PropTypes.func,
}

ModalComponent.defaultProps = {
  isOpen: false,
  withBackdrop: true,
  onClose: () => {},
}

export default ModalComponent
