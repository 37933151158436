import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, CheckboxGroup } from 'rsuite'

import * as style from './styles'

/**
 * <Describe the Checkbox component here>
 *
 * @component
 * @usage import Checkbox from '~/components/Checkbox'
 * @example
 * <Add an example of how Checkbox is used here>
 */
const CheckboxComponent = ({ children, isChecked, isIndeterminate, onChange, ...extra }) => {
  return (
    <Checkbox
      checked={isChecked}
      indeterminate={isIndeterminate}
      css={style.checkbox}
      onChange={(val, checked) => onChange(checked)}
      {...extra}
    >
      {children}
    </Checkbox>
  )
}

CheckboxComponent.propTypes = {
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  onChange: PropTypes.func,
}

CheckboxComponent.defaultProps = {
  isChecked: false,
  isIndeterminate: false,
  onChange: () => {},
}

export default CheckboxComponent
