import React from 'react'
import PropTypes from 'prop-types'

import { Input as RSuiteInput } from 'rsuite'
import { TextArea, DatePicker, Checkbox } from '~/components/common'
import { isValidDate } from '~/helpers/dateHelper'
import { isNil } from 'lodash'

import * as style from './styles'

/**
 * <Describe the Input component here>
 *
 * @component
 * @usage import Input from '~/components/Input'
 * @example
 * <Add an example of how Input is used here>
 */
const Input = ({ label, isDisabled, isRequired, defaultValue, hasError, containerCss, type, validation, ...extra }) => {
  let InputComponent = RSuiteInput
  const inputProps = {
    disabled: isDisabled,
    css: style.input(hasError),
    type,
    defaultValue,
    ...extra,
  }

  if (validation && validation.includes('required')) {
    isRequired = true
  }

  const typeLowered = type.toLowerCase()

  if (typeLowered === 'textarea') {
    InputComponent = TextArea
  } else if (typeLowered === 'date') {
    InputComponent = DatePicker
    if (isValidDate(new Date(defaultValue))) {
      inputProps.defaultValue = new Date(defaultValue)
    } else {
      inputProps.defaultValue = ''
    }
  } else if (typeLowered === 'checkbox') {
    return (
      <div css={[style.container, containerCss]}>
        <Checkbox
          isChecked={isNil(inputProps.value) ? defaultValue : inputProps.value}
          {...inputProps}
        >
          {label}
        </Checkbox>
      </div>
    )
  }

  return (
    <div css={[style.container, containerCss]}>
      <InputComponent
        {...inputProps}
      />
      {label && (
        <label css={style.label(hasError)}>
          {label}
          {isRequired && (<span css={style.requiredAsterisk}>*</span>)}
        </label>
      )}
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  containerCss: PropTypes.object,
  type: PropTypes.string,
  validation: PropTypes.string,
}

Input.defaultProps = {
  label: '',
  isDisabled: false,
  containerCss: {},
  type: 'text',
  validation: '',
}

export default Input
