import Service from './Service'

export const SERVICES = {
  get: {
    type: 'get',
  },
}

/**
 * Instance of Titles Service.
 *
 * @class
 * @usage `import TitlesService from '~/network/TitlesService'`
 */
export default class TitlesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'titles'
  }
}
