// ? This is to make any modifications to existing resumes.

const parseExistingResume = (resume) => {
  // ? Modification 1:
  // ? We changed the key `label` in job experiences to `employer`.
  // todo (APR. 12 2021): This modification should be remove once the app goes production and old resumes are purged from the DB
  resume.details.experience.forEach(experience => {
    experience.employer = experience.label
    delete experience.label
  })

  return resume
}

export default parseExistingResume