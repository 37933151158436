import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '~/components/common'
import InputMinimalistic from '~/components/InputMinimalistic'
import Countdown from '~/components/Countdown'
import { getDifferenceInSeconds } from '~/helpers/dateHelper'
import { OTP_LENGTH } from '~/constants'

import * as style from '../../styles'

const CountdownWithRequestButton = ({ onCountdownComplete, onResendOtp, otpExpiryDate, isLoading, canRequestOtp }) => {
  const timerInSeconds = otpExpiryDate ? parseInt(getDifferenceInSeconds(otpExpiryDate, new Date())) : 1

  return (
    <>
      <small>
        Password will expire in <Countdown onComplete={onCountdownComplete} time={timerInSeconds} />.
      </small>
      <Button
        appearance='link'
        isLoading={isLoading}
        isDisabled={!canRequestOtp}
        css={[style.button, style.buttonResend]}
        onClick={onResendOtp}
      >
        Resend One-Time Password
      </Button>
    </>
  )
}


const ScreenOtpInput = ({ email, onChange, isLoading, onConfirm, onRequestOtp, otpExpiryDate, inputError }) => {
  const [canRequestOtp, setCanRequestOtp] = useState(false)

  const handleConfirm = () => {
    onConfirm()
    setCanRequestOtp(false)
  }

  const handleRequestOtp = () => {
    setCanRequestOtp(false)
    onRequestOtp()
  }

  return (
    <>
      <b css={style.title}>
        One-Time Password Verification
      </b>
      <InputMinimalistic
        label={<>Enter the OTP we sent to: <span css={style.email}>{email}</span></>}
        type={`otp|${OTP_LENGTH}`}
        onChange={onChange}
        onEnter={handleConfirm}
        isDisabled={isLoading}
        cssContainer={style.inputEmailContainer}
        error={inputError}
      />
      <Button
        theme='dark'
        isLoading={isLoading}
        css={[style.button, style.buttonValidate]}
        onClick={handleConfirm}
      >
        Validate
      </Button>
      <CountdownWithRequestButton
        onCountdownComplete={() => setCanRequestOtp(true)}
        onResendOtp={handleRequestOtp}
        otpExpiryDate={otpExpiryDate}
        isLoading={isLoading}
        canRequestOtp={canRequestOtp}
      />
    </>
  )
}

ScreenOtpInput.propTypes = {
  email: PropTypes.string,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestOtp: PropTypes.func,
  otpExpiryDate: PropTypes.string,
  inputError: PropTypes.string,
}

ScreenOtpInput.defaultProps = {
  email: '',
  onChange: () => {},
  onConfirm: () => {},
  isLoading: false,
  onRequestOtp: () => [],
  otpExpiryDate: '',
  inputError: '',
}

export default ScreenOtpInput