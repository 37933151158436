import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'rsuite'

import * as style from './styles'

/**
 * <Describe the Loader component here>
 *
 * @component
 * @usage import Loader from '~/components/Loader'
 * @example
 * <Add an example of how Loader is used here>
 */
const Loader = ({ ...extra }) => (
  <Icon spin icon='circle-o-notch' css={style.loader} {...extra} />
)

Loader.propTypes = {
}

Loader.defaultProps = {
}

export default Loader
