import Store from './Store'
import AttachmentsService from '~/network/AttachmentsService.js'

class AttachmentsStore extends Store {
  constructor(initialData) {
    super(initialData)
  }

  async getLatestAttachment(params) {
    const { stateStore } = this.rootStore

    return await this.storeApiResponse({
      apiName: 'getLatestAttachment',
      params,
      onSuccess: (resp) => {
        if (resp.data) {
          this.rootStore.resumesStore.setUploadedDataPdfAttachment(resp.data)
        }
      },
    })
  }

  service() {
    return new AttachmentsService(this.rootStore)
  }
}

export default AttachmentsStore
