import Store from './Store'
import RecommendationsService from '~/network/RecommendationsService.js'

class RecommendationsStore extends Store {
  constructor(initialData) {
    super(initialData)
  }

  service() {
    return new RecommendationsService(this.rootStore)
  }
}

export default RecommendationsStore
