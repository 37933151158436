import React from 'react'
import PropTypes from 'prop-types'

import { InputPicker } from 'rsuite'
import Loader from '~/components/Loader'

import * as style from './styles'

/**
 * <Describe the Select component here>
 *
 * @component
 * @usage import Select from '~/components/common/Select' || import { Select } from '~/components/common'
 * @example
 * <Add an example of how Select is used here>
 */
const Select = ({ options, color, size, isBlock, isLoading, ...extra }) => {
  return (
    <InputPicker
      data={options}
      size={size}
      block={isBlock}
      renderValue={(value, item, selectedElement) => (
        <div key={`${value}-${item}`} css={style.selectValueContainer}>
          <div css={style.colorIndicator(color)} /> {selectedElement}
        </div>
      )}
      renderMenu={menu => {
        if (isLoading) {
          return (
            <div css={style.loaderContainer}>
              <Loader css={style.loaderIcon} /> Searching...
            </div>
          )
        }

        return menu
      }}
      style={isBlock ? { width: '100%', display: 'block' } : {}}
      {...extra}
    />
  )
}

Select.propTypes = {
  size: PropTypes.string,
  isBlock: PropTypes.boolean,
}

Select.defaultProps = {
  size: 'lg',
  isBlock: false,
}

export default Select
