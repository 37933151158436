/* eslint-disable react/no-array-index-key */
import React from 'react'

import * as style from './styles'

const InputOtp = ({ typeLength, onChange, onKeyPress, isDisabled, error, ...extra }) => {
  const handleOTPChange = (key, index) => {
    onKeyPress({ key })
    const isNumber = !isNaN(key)
    const existingInputValue = document.getElementById(`otp_input-${index}`).value
    const hasReachedEnd = index + 1 === typeLength

    if (!isNumber && !existingInputValue) {
      setTimeout(() => {
        document.getElementById(`otp_input-${index}`).value = ''
      }, 1)
      return
    } else if (!isNumber && existingInputValue) {
      return
    }

    if (hasReachedEnd && existingInputValue === key) {
      return
    } else if (hasReachedEnd && existingInputValue && existingInputValue !== key) {
      setTimeout(() => {
        document.getElementById(`otp_input-${index}`).value = key
      }, 1)
    }

    onChange({ key: index, value: Number(key) })

    if (!hasReachedEnd) {
      setTimeout(() => {
        document.getElementById(`otp_input-${index + 1}`).select()
      }, 1)
    }
  }

  const handleOTPErase = (key, index) => {
    const hasReachedBegining = index - 1 >= 0

    if (key === 'Backspace') {
      onChange({ key: index, value: '' })

      const existingInputValue = document.getElementById(`otp_input-${index}`).value
      if (existingInputValue) {
        document.getElementById(`otp_input-${index}`).innerHTML = ''
      } else if (hasReachedBegining) {
        document.getElementById(`otp_input-${index - 1}`).select()
      }
    }
  }

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || event.originalEvent.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text').trim()

    pastedData.split('').forEach((char, index) => {
      if (char) {
        document.getElementById(`otp_input-${index}`).value = char
        onChange({ key: index, value: Number(char) })
      }
    })
  }

  return (
    <div>
      {
        Array(typeLength).fill(typeLength).map((ev, index) => (
          <input
            key={`otp_input-${index}`}
            onKeyPress={ev => handleOTPChange(ev.key, index)}
            onKeyDown={ev => handleOTPErase(ev.key, index)}
            onPaste={ev => handlePaste(ev)}
            id={`otp_input-${index}`}
            disabled={isDisabled}
            maxLength='1'
            css={[style.inputMinimal(error), style.inputOTP]}
            {...extra}
          />
        ))
      }
    </div>
  )
}

export default InputOtp
