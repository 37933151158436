import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { Icon } from '~/components/common'
import { validateEmail } from '~/utils/validators'
import ScreenEmailInput from './screens/EmailInput'
import ScreenOtpInput from './screens/OtpInput'
import tracker from '~/lib/tracker'
import { OTP_LENGTH } from '~/constants'

import * as style from './styles'

/**
 * <Describe the OtpLogin component here>
 *
 * @component
 * @usage import OtpLogin from '~/components/OtpLogin'
 * @example
 * <Add an example of how OtpLogin is used here>
 */
const OtpLogin = ({ isActive, onClose, rootStore }) => {
  const { authStore, stateStore } = rootStore
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [inputError, setInputError] = useState('')
  const [hasRequestedOtp, setHasRequestedOtp] = useState(false)
  const [otpExpiryDate, setOtpExpiryDate] = useState()

  const handleClose = () => {
    onClose()
    setOtp({})
    setHasRequestedOtp(false)
  }

  const handleSubmitEmail = async () => {
    setIsLoading(true)
    setInputError('')
    setOtpExpiryDate(0)

    if (validateEmail(email)) {
      const otpRequest = await authStore.api().requestOtp({ email })

      if (otpRequest.success) {
        setOtpExpiryDate(otpRequest.data.otp_expires_at)
        setHasRequestedOtp(true)
      } else {
        stateStore.setSyncStatus('failure')
      }

      setIsLoading(false)
    } else {
      setIsLoading(false)
      setInputError('Please enter a valid email')
    }
  }

  const handleOtpChange = ({ key, value }) => {
    setOtp(oldOTP => {
      const newOTP = { ...oldOTP }
      newOTP[key] = value
      return newOTP
    })
  }

  const handleSubmitOTP = async () => {
    setIsLoading(true)
    setInputError('')
    const otpJoined = Object.values(otp).join('')

    if (otpJoined.length < OTP_LENGTH) {
      setIsLoading(false)
      setInputError('Please enter all the numbers')
    } else {
      const otpRequest = await authStore.api().validatetOtp({ email, otp: otpJoined })

      if (otpRequest.success) {
        const userInfo = otpRequest.data
        authStore.setCurrentUser(userInfo)
        tracker.event('Login', { userId: userInfo.id })
        setTimeout(() => {
          handleClose()
          setIsLoading(false)
          otpRequest.success && stateStore.onLoginSuccess(userInfo)
        }, 1000)
      } else {
        stateStore.setSyncStatus('failure')
        setIsLoading(false)
      }
    }
  }

  const CloseIcon = () => (
    <Icon
      icon='close'
      onClick={handleClose}
      css={style.iconClose}
    />
  )

  if (!isActive) {
    return null
  }

  return (
    <div css={style.otpLoginContainer}>
      <CloseIcon />
      {
        !hasRequestedOtp
          ? (
            <ScreenEmailInput
              onChange={setEmail}
              isLoading={isLoading}
              onConfirm={handleSubmitEmail}
              inputError={inputError}
            />
          )
          : (
            <ScreenOtpInput
              email={email}
              onChange={handleOtpChange}
              isLoading={isLoading}
              onConfirm={handleSubmitOTP}
              onRequestOtp={handleSubmitEmail}
              otpExpiryDate={otpExpiryDate}
              inputError={inputError}
            />
          )
      }
    </div>
  )
}

OtpLogin.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
}

OtpLogin.defaultProps = {
  isActive: false,
  onClose: () => {},
}

export default inject('rootStore')(observer(OtpLogin))
