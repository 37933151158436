import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'rsuite'
import { GoArrowUp, GoArrowDown, GoArrowLeft, GoArrowRight } from 'react-icons/go'
import { FaCoins, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { MdDragHandle } from 'react-icons/md'
import { BsFileEarmarkText } from 'react-icons/bs'
import { VscChromeClose, VscCheck } from 'react-icons/vsc'
import { AiOutlineLink, AiOutlinePlus } from 'react-icons/ai'
import { FiEdit2 } from 'react-icons/fi'
import { HiThumbUp, HiThumbDown } from 'react-icons/hi'
import { IoMdAddCircle, IoIosWarning } from 'react-icons/io'
import { BiTrash } from 'react-icons/bi'

import IconLinkedIn from '~/public/static/icons/icon-linkedin.svg'
import IconUpload from '~/public/static/icons/icon-upload.svg'

import * as style from './styles'

const ALIASES = {
  edit: FiEdit2,
  check: VscCheck,
  coins: FaCoins,
  arrowUp: GoArrowUp,
  arrowDown: GoArrowDown,
  arrowLeft: GoArrowLeft,
  arrowRight: GoArrowRight,
  chevronUp: FaChevronUp,
  chevronDown: FaChevronDown,
  chevronLeft: FaChevronLeft,
  chevronRight: FaChevronRight,
  drag: MdDragHandle,
  link: AiOutlineLink,
  linkedIn: IconLinkedIn,
  upload: IconUpload,
  file: BsFileEarmarkText,
  close: VscChromeClose,
  thumbsUp: HiThumbUp,
  thumbsDown: HiThumbDown,
  addCircle: IoMdAddCircle,
  add: AiOutlinePlus,
  trash: BiTrash,
  warning: IoIosWarning,
}

/**
 * <Describe the Icon component here>
 *
 * @component
 * @usage import Icon from '~/components/Icon'
 * @example
 * <Add an example of how Icon is used here>
 */
const IconComponent = ({ icon, ...extra }) => {
  const AliasIcon = ALIASES[icon]

  if (AliasIcon) {
    return <AliasIcon {...extra} />
  }

  return (
    <Icon icon={icon} {...extra} />
  )
}

IconComponent.propTypes = {
  icon: PropTypes.string.isRequired,
}

IconComponent.defaultProps = {
  icon: '',
}

export default IconComponent
