export default class Store {
  constructor({ rootStore, ...props } = {}) {
    this.rootStore = rootStore

    this.setObservablesFromServerToClient(props)
    if (this.service) {
      this.serviceApis = this.service().api()
    }
  }

  setRootStore(rootStore) {
    this.rootStore = rootStore
  }

  api() {
    return this.serviceApis || {}
  }

  setObservablesFromServerToClient(props) {
    Object.keys(props).forEach(key => {
      const propValue = props[key]
      if (propValue) {
        this[key] = propValue
      }
    })
  }
}
