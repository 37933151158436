import { Service } from '~/resources/network'
import { API_BASE } from '~/global.config'

const DEFAULT_TIMEOUT = 300000 // 5 min

const AXIOS_CONFIG = {
  baseURL: API_BASE,
  timeout:  DEFAULT_TIMEOUT,
}

export default class ProjectService extends Service {
  constructor(props) {
    super({
      ...props,
      axiosConfig: AXIOS_CONFIG,
    })
  }
}
