import Api from './Api'
import { trim } from 'lodash'
import { Regex } from '~/resources/utils'

const PARAMS_PREFIX = ':'

export default class Service extends Api {
  constructor(props) {
    super(props)
  }

  api() {
    const servicesToReturn = {}
    const services = this.services

    Object.keys(services).forEach(serviceKey => {
      const {
        type = 'get',
        serviceBase = '',
        urlParam = '',
        extraParams = {},
        extraHeaders = {},
        present = resp => resp,
      } = services[serviceKey]

      const replaceUrlParams = (url, params) =>
        url.replace(Regex(Regex.patterns.paramsStrict), variable => {
          const variableTrimmed = trim(variable, `${PARAMS_PREFIX}`)
          return `${params[variableTrimmed]}`
        })

      servicesToReturn[serviceKey] = async params => {
        const allParams = { ...extraParams, ...params }
        const UrlParamParsed = replaceUrlParams(urlParam, allParams)
        const serviceUrl = `${serviceBase || this.serviceBase()}${UrlParamParsed}`

        const resp = await this[type](serviceUrl, {
          params: allParams,
          headers: extraHeaders,
        })

        return present(resp)
      }
    })

    return servicesToReturn
  }
}
