export const zIndexes = {
  under: 0,
  bottom: 1,
  middle: 5,
  top: 10,
  header: 15,
  overlay: 20,
  notficiations: 25,
  godmode: 100,
}

export const headerHeight = '4.5rem'

export const themeColors = [
  { main: 'primary', alt: 'primaryAlt' },
  { main: 'secondary', alt: 'secondaryAlt' },
  { main: 'tertiary', alt: 'tertiaryAlt' },
]
