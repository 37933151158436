import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Button, IconButton, Icon as RsuiteIcon } from 'rsuite'
import { Link } from '~/lib/router'
import { isEmpty } from 'lodash'
import Icon from '../Icon'
import trackerLib from '~/lib/tracker'

import * as style from './styles'

const ButtonContainer = (props) => (
  <div css={style.buttonContainer} {...props} />
)

const ButtonIcon = ({ icon, ...props }) => (
  <IconButton icon={<RsuiteIcon icon={icon} />} circle {...props} />
)

/**
 * <Describe the Button component here>
 *
 * @component
 * @usage import Button from '~/components/common/Button' || import { Button } from '~/components/common'
 * @example
 * <Add an example of how Button is used here>
 */
const ButtonComponent = ({ children, theme, isPill, href, params, icon, iconCss, isDisabled, isLoading, onClick, tracker, ...extra }) => {
  let ButtonWrapper = Fragment
  let buttonWrapperProps = {}
  const isDarkTheme = theme === 'dark'

  const handleClick = (ev) => {
    let eventName = 'Click'
    let eventParams = { button: children }
    const { event, params } = tracker

    if (event) {
      eventName = event
    }

    if (params && !isEmpty(params)) {
      eventParams = params
    }

    trackerLib.event(eventName, eventParams)
    onClick(ev)
  }

  if (href && !isEmpty(href)) {
    ButtonWrapper = Link
    buttonWrapperProps = {
      href,
      params,
      css: style.anchor,
      target: extra.target,
      ref: extra.ref,
    }
  }

  return (
    <ButtonWrapper {...buttonWrapperProps}>
      <Button
        color={isDarkTheme ? 'blue' : null}
        disabled={isDisabled}
        loading={isLoading}
        {...extra}
        css={style.button({ isPill, theme })}
        onClick={handleClick}
      >
        {icon && <Icon icon={icon} css={!isEmpty(iconCss) ? iconCss : style.icon} />}
        {children}
      </Button>
    </ButtonWrapper>
  )
}

ButtonComponent.Group = ButtonContainer
ButtonComponent.Icon = ButtonIcon

ButtonComponent.propTypes = {
  isPill: PropTypes.bool,
  theme: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  params: PropTypes.object,
  iconCss: PropTypes.object,
  onClick: PropTypes.func,
  tracker: PropTypes.object,
}

ButtonComponent.defaultProps = {
  isPill: false,
  theme: 'light',
  href: '',
  params: {},
  iconCss: {},
  onClick: () => {},
  tracker: { event: '', params: {} },
}

export default ButtonComponent
